import React, { useCallback, useEffect, useRef, useState } from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import useSWR from 'swr'
import useOnScreen from 'shared/hooks/use-on-screen'
import useUser from 'shared/hooks/use-user'
import LoadingIcon from 'shared/icons/loading-icon'
import { baseServerSideProps } from 'shared/utils/base-server-side-props'
import MobileNav from '../../../modules/header/components/mobile-nav'
import { likePost, unlikePost } from 'modules/community/api/community-api'
import * as postsApi from 'modules/community/api/posts-api'
import AddPost from 'modules/community/components/manage-post/create-post'
import PostAnnounce, { PostAnnounceSkeleton } from 'modules/community/components/post-announce'
import { ScrollToTopButton } from 'modules/community/components/scroll-to-top-button'
import { ACTIVITY_CLEANUP_PERIOD_DURATION } from 'modules/community/const/post-consts'
import { BanPeriodUnitEnum } from 'modules/community/enums/member-enum'
import useCommentsInfo from 'modules/community/hooks/use-comments-info'
import { useCommunityWithTopics } from 'modules/community/hooks/use-community-with-topics'
import useIsUserMember from 'modules/community/hooks/use-is-user-member'
import { PostInterface } from 'modules/community/types/post-interface'
import { getPostActions } from 'modules/post/utils/post-utils'

function Community() {
  const { t } = useTranslation()
  const ref = useRef<HTMLDivElement>(null)
  const isVisible = useOnScreen(ref)
  const [isLoading, setLoading] = useState(false)
  const [isEnd, setIsEnd] = useState(false)
  const [lastPostId, setLastPostId] = useState(0)
  const router = useRouter()
  const { user } = useUser()
  const { isUserMember } = useIsUserMember()
  const { communityPath } = router.query
  const { data: community } = useCommunityWithTopics()
  const { mutate, data: posts = [], isLoading: isLoadingPosts } = useSWR<PostInterface[]>('posts')
  const [postsIds, setPostsIds] = useState<number[]>([])
  useCommentsInfo({ mutatePosts: mutate, postIds: postsIds })

  const loadPosts = useCallback(async () => {
    try {
      setLoading(true)
      const { data } = await postsApi.getPostsByLastId(communityPath as string, lastPostId)
      const lastPost = data.items.at(-1)
      if (lastPost) {
        setLastPostId(lastPost.id)
      }
      if (!data.hasMore) {
        setIsEnd(true)
      }
      await mutate((prevPosts = []) => [...prevPosts, ...data.items])
      const newIds = data.items.map(post => post.id)
      setPostsIds(prev => [...prev, ...newIds])
      setLoading(false)
    } catch (e) {
      setIsEnd(true)
      toast.error(t('core.error.internal_error_message'))
      setLoading(false)
    }
  }, [communityPath, lastPostId, mutate, t])

  const handleLikeClick =
    ({ id, topic, path, isLiked }: PostInterface) =>
    async () => {
      const { globalMutatePost } = getPostActions({
        communityPath: communityPath as string,
        topicPath: topic.path,
        postPath: path,
      })

      try {
        if (isLiked) {
          await unlikePost(id)
          await mutate(
            data =>
              data &&
              data.map(post =>
                post.id === id
                  ? { ...post, isLiked: false, likesCount: post.likesCount - 1 }
                  : post,
              ),
            false,
          )
          globalMutatePost(
            post => post && { ...post, isLiked: false, likesCount: post?.likesCount - 1 },
          )
        } else {
          await likePost(id)
          await mutate(
            data =>
              data &&
              data.map(post =>
                post.id === id ? { ...post, isLiked: true, likesCount: post.likesCount + 1 } : post,
              ),
            false,
          )
          globalMutatePost(
            post => post && { ...post, isLiked: true, likesCount: post?.likesCount + 1 },
          )
        }
      } catch (err) {
        toast.error(t('post_details.error.error_like_post'))
      }
    }

  useEffect(() => {
    setLastPostId(0)
    setIsEnd(false)
    mutate(() => [])
  }, [])

  useEffect(() => {
    if (isVisible && !isLoading && community && !isEnd && user) {
      loadPosts()
    }
  }, [isVisible, community, isLoading, isEnd, user])

  function deletePost(postId: number) {
    return async () => {
      await postsApi.deletePost(postId)
      const filteredPosts = posts.filter(p => p.id !== postId)
      await mutate(filteredPosts, false)
    }
  }

  function deletePostAndBanUser(postId: number, userId: number) {
    return async (removeRecentActivitiesChecked: boolean) => {
      await postsApi.banUser(
        communityPath as string,
        userId,
        removeRecentActivitiesChecked
          ? {
              deleteRecentActivityOptions: {
                periodUnit: BanPeriodUnitEnum.day,
                periodDuration: ACTIVITY_CLEANUP_PERIOD_DURATION,
              },
            }
          : undefined,
      )
      await postsApi.deletePost(postId)
      if (removeRecentActivitiesChecked) {
        await mutate()
        return
      }
      const filteredPosts = posts.filter(p => p.id !== postId)
      await mutate(filteredPosts, false)
    }
  }

  const handlePostAdd = (post: PostInterface) =>
    setPostsIds(ids => Array.from(new Set(ids.concat(post.id))))

  return (
    <>
      <MobileNav />
      <div>
        {isUserMember && <AddPost onAdd={handlePostAdd} />}
        {isLoading && posts.length === 0 && (
          <>
            <PostAnnounceSkeleton key={1} />
            <PostAnnounceSkeleton key={2} />
          </>
        )}
        {posts.map(post => (
          <PostAnnounce
            key={post.id}
            deletePost={deletePost(post.id)}
            deletePostAndBanUser={deletePostAndBanUser(post.id, post.userId)}
            post={post}
            handleLikeClick={handleLikeClick(post)}
            dashboardLocale={user?.dashboardLocale}
          />
        ))}
        {isLoading && posts.length > 0 && (
          <div className="flex justify-center">
            <LoadingIcon />
          </div>
        )}
        <div ref={ref} />
      </div>
      <ScrollToTopButton />
    </>
  )
}

export const getServerSideProps = baseServerSideProps

export default Community
